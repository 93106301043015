<template>
  <div class=" h-100 position-relative " style="width:100%">
    <div class=" position-absolute line1Wrapper"
      style="height:0.5%;width:120%;left:0%;top:50%;transform:translate(0%, -50%);">
      <div class=" bg-warning line1" style="height:100%;width:0%"></div>
      <inline-svg src="images/Slide3/circle-1.svg" aria-label="Dot" class="position-absolute circle-line-1 text-warning"
        style="width:3vh;right:0%;top:50%;transform:translate(50%, -50%) scale(0);" />
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() { },
  methods: {
    line1Width(percentage) {
      let line1animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 600
      })

      line1animation.add({
        targets: ".line1",
        width: ["0%", "100%"],
        duration: 600,
        delay: 0,
        easing: "linear"
      })

      line1animation.seek(line1animation.duration * percentage)
    },
    line1Left(percentage) {
      let line1animationLeft = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      line1animationLeft
        .add({
          targets: ".line1Wrapper",
          left: ["20%", "-60%"],
          duration: 600,
          delay: 0,
          easing: "linear"
        })
        .add({
          targets: ".line1Wrapper",
          left: "-150%",
          duration: 600,
          delay: 0,
          easing: "linear"
        })
      line1animationLeft.seek(line1animationLeft.duration * percentage)
    },
    circleLine1(percentage) {
      let circleLine1 = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 100
      })

      circleLine1.add({
        targets: ".circle-line-1",
        scale: [0, 1],
        duration: 200,
        delay: 300,
        easing: "easeInOutCubic"
      })

      circleLine1.seek(circleLine1.duration * percentage)
    }
  },
  watch: {
    innerPosition() {
      this.line1Width(this.innerPosition)
      this.line1Left(this.innerPosition)
      this.circleLine1(this.innerPosition)
    }
  }
}
</script>
<style></style>
